import {
  CopyMeasurementToClipboardEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { selectIsMeasurementBeingTaken } from "@/store/measurement-tool-selector";
import { ComponentsToDisplay } from "@/store/measurement-tool-slice";
import { useAppSelector } from "@/store/store-hooks";
import {
  LineHandler,
  neutral,
  useNonExhaustiveEffect,
  useSvg,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { HtmlProps } from "@react-three/drei/web/Html";
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import {
  MutableRefObject,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Group, Material, Matrix4, Object3D, Vector3 } from "three";
import { AnnotationVisibility } from "../annotations/annotation-utils";
import { CollapsedMeasureRenderer } from "./collapsed-measure-renderer";
import {
  CopyToClipboardParameters,
  MeasureActionBar,
} from "./measure-action-bar";
import { BIG_HANDLER_SIZE } from "./measure-constants";
import { HandlerRenderer } from "./measure-handler";
import { computeMultiPointMeasurementDescription } from "./measure-utils";
import { MultiSegmentRenderer } from "./multi-segment-renderer";
import { ShapeLabel, ShapeLabelActions } from "./shape-label";

/** Props of TwoPointMeasureRenderer component */
export type MultiPointMeasureRendererProps = {
  /** The list of points in the measurement, in world coordinates */
  points: Vector3[];

  /** The measurement unit type */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Callback when the measurement unit is changed */
  onToggleUnitOfMeasure(): void;

  /** True if this measurement is active (selected) */
  active?: boolean;

  /** True if another measurement is currently live */
  otherLive?: boolean;

  /** True if this is the measurement that is currently being measured */
  live?: boolean;

  /** True if the pointer events should be disabled for this measurement */
  disablePointerEvents?: boolean;

  /**
   * Callback providing center position of the segment when a label is clicked
   *
   * @param position of the segment center
   * @param firstPointIndex index of the first point for this segment; undefined when clicking on the area label
   */
  onClick?(position: Vector3, firstPointIndex?: number): void;

  /** Visibility flag determining if and how this measure should be rendered */
  visibility: AnnotationVisibility;

  /** Whether depth testing should be used to render the measurement */
  depthTest?: Material["depthTest"];

  /** The render order to use for the measurement */
  renderOrder?: Object3D["renderOrder"];

  /** Callback executed when clicking the button to create an annotation */
  onCreateAnnotation?(): void;

  /** When a collapsed measure is clicked, this callback is issued to move the camera towards the measure */
  onCollapsedMeasurementClicked?(point: Vector3): void;

  /** Callback when the delete option in the action bar is clicked */
  onDeleteActiveMeasurement?(): void;

  /** Callback when the user changed the components to display; undefined when user cannot change the components to display*/
  onChangeMeasurementComponentsToDisplay?(
    componentsToDisplay: ComponentsToDisplay,
  ): void;

  /** Flag specifying if the measurement is a closed polygon */
  isClosed: boolean;

  /** Which components are display in the measurements. Undefined if the button to edit components should be disabled. */
  componentsToDisplay?: ComponentsToDisplay;

  /** Show/hide the action bar */
  showActionBar?: boolean;

  /**
   * Callback executed when clicking on an handler
   *
   * @param ev The event that triggered the handler
   * @param index The 0-based index of the point in the points array
   */
  onHandlerClicked?(ev: ThreeEvent<MouseEvent>, index: number): void;

  /**
   * Callback executed when triggering the context event (e.g. right click on desktop devices)
   *
   * @param ev The event that triggered the handler
   * @param index The 0-based index of the point in the points array
   */
  onHandlerContextMenu?(ev: ThreeEvent<MouseEvent>, index: number): void;

  /**
   * Callback executed when hovering an handler
   *
   * @param index The 0-based index of the point in the points array, -1 if no handeler is hovered
   */
  onHandlerHovered?(index: number): void;
};

/**
 * @returns A R3F component to render a distance between two points, together with the
 * components along the XYZ axes.
 */
export const MultiPointMeasureRenderer = forwardRef<
  Group,
  MultiPointMeasureRendererProps
>(function MultiPointMeasureRenderer(
  {
    points,
    active,
    otherLive,
    live,
    disablePointerEvents,
    onClick,
    visibility,
    depthTest,
    renderOrder,
    onCollapsedMeasurementClicked,
    onDeleteActiveMeasurement,
    onChangeMeasurementComponentsToDisplay,
    onCreateAnnotation,
    unitOfMeasure,
    onToggleUnitOfMeasure,
    isClosed = false,
    componentsToDisplay,
    onHandlerClicked,
    onHandlerContextMenu,
    onHandlerHovered,
    showActionBar,
  }: MultiPointMeasureRendererProps,
  ref,
): JSX.Element | null {
  const labelContainer = useViewOverlayRef();

  // Compute the points average
  const position = useMemo(
    () =>
      Object.freeze(
        points
          .reduce((prev, next) => prev.add(next), new Vector3())
          .divideScalar(points.length),
      ),
    [points],
  );

  const [toolbarAnchorPoint, setToolbarAnchorPoint] = useState<
    Vector3 | undefined
  >(undefined);

  const [firstPointIndex, setFirstPointIndex] = useState<number | undefined>();

  const isMeasurementBeingTaken = useAppSelector(selectIsMeasurementBeingTaken);
  useNonExhaustiveEffect(() => {
    // isMeasurementBeingTaken toggled to false when measurement completed
    // if we have 2 points or more at that moment set toolbar anchor point on top of last measurement label
    if (points.length >= 2 && !isMeasurementBeingTaken) {
      // label/anchor position recomputed here, because there is no easy way to get it from place
      // from the code, computing position for labels rendering in grand-child component MultiSegmentRenderer.
      // it could be refactored, but at the moment efforts looks not worth.
      // computation is very small and one point computation can be simply re-done here instead of setting
      // complex dependencies or refactoring.
      const point = new Vector3()
        .addVectors(
          points[points.length - 1],
          isClosed ? points[0] : points[points.length - 2],
        )
        .multiplyScalar(0.5);

      setToolbarAnchorPoint(point);
      setFirstPointIndex(isClosed ? points.length - 1 : points.length - 2);
    }
  }, [isMeasurementBeingTaken]);

  const camera = useThree((s) => s.camera);
  const onMinifiedMeasureClicked = useCallback(
    (position: Vector3) => {
      // compute an horizontal movement vector that brings the camera
      // at three meters from the measurement's midpoint.
      const refPosition = position.clone();
      refPosition.y = camera.position.y;
      const distanceVec = new Vector3().subVectors(
        refPosition,
        camera.position,
      );
      const distance = distanceVec.length();
      const factor = distance > 0 ? (distance - 3) / distance : 0;
      distanceVec.multiplyScalar(factor);
      onCollapsedMeasurementClicked?.(camera.position.clone().add(distanceVec));

      setToolbarAnchorPoint(position);
      setFirstPointIndex(undefined);
    },
    [camera, onCollapsedMeasurementClicked],
  );

  const onLabelClick = useCallback(
    (position: Vector3, firstPointIndex: number) => {
      setToolbarAnchorPoint(active ? undefined : position);
      setFirstPointIndex(firstPointIndex);
      onClick?.(position, firstPointIndex);
    },
    [active, onClick],
  );

  if (visibility === AnnotationVisibility.NotVisible) {
    return null;
  }

  return (
    <group
      ref={ref}
      // Setting the render order on a group, also makes it apply to all children
      renderOrder={renderOrder}
    >
      <CollapsedMeasureRenderer
        visible={visibility === AnnotationVisibility.Minified}
        position={position}
        parentRef={labelContainer}
        onClick={onMinifiedMeasureClicked}
        enabled={!otherLive}
      />
      <MultiPointRenderer
        live={live}
        isMeasurementActive={active}
        isLabelActive={active}
        labelContainer={labelContainer}
        visible={visibility !== AnnotationVisibility.Minified}
        depthTest={depthTest}
        unitOfMeasure={unitOfMeasure}
        labelsPointerEvents={disablePointerEvents ? "none" : "auto"}
        onClick={onLabelClick}
        points={points}
        isClosed={isClosed}
        componentsToDisplay={componentsToDisplay}
        onHandlerClicked={onHandlerClicked}
        onHandlerContextMenu={onHandlerContextMenu}
        onHandlerHovered={onHandlerHovered}
        onDeleteActiveMeasurement={onDeleteActiveMeasurement}
        onToggleUnitOfMeasure={onToggleUnitOfMeasure}
        showActionBar={showActionBar}
        onCreateAnnotation={onCreateAnnotation}
        onChangeMeasurementComponentsToDisplay={
          onChangeMeasurementComponentsToDisplay
        }
        toolbarAnchorPoint={toolbarAnchorPoint}
        firstPointIndex={firstPointIndex}
        isLabelVisible
      />
    </group>
  );
});

type MultiPointRendererProps = Pick<
  MultiPointMeasureRendererProps,
  | "points"
  | "live"
  | "isClosed"
  | "componentsToDisplay"
  | "unitOfMeasure"
  | "onHandlerClicked"
  | "onHandlerContextMenu"
  | "onHandlerHovered"
  | "onClick"
  | "onToggleUnitOfMeasure"
  | "onDeleteActiveMeasurement"
  | "onChangeMeasurementComponentsToDisplay"
  | "showActionBar"
  | "onCreateAnnotation"
  | "depthTest"
> & {
  /** True if the user selected this measurement as the active one */
  isMeasurementActive: boolean | undefined;

  /** True if this label is the active one */
  isLabelActive: boolean | undefined;

  /** Reference to the HTMLElement to use as a parent for the labels */
  labelContainer: MutableRefObject<HTMLElement>;

  /** The pointer events allowed on the labels */
  labelsPointerEvents: HtmlProps["pointerEvents"];

  /** Show/Hide this component */
  visible: boolean;

  /** True if the label should be rendered */
  isLabelVisible: boolean;

  /** Position to be used as an anchor point for measurement toolbar  */
  toolbarAnchorPoint?: Vector3;

  /** Index of the first point for this segment; undefined when the whole measurement should be handled */
  firstPointIndex?: number;
};

/** @returns the multi point measurement renderer component */
export function MultiPointRenderer({
  live,
  isMeasurementActive = false,
  labelContainer,
  visible,
  depthTest,
  unitOfMeasure,
  labelsPointerEvents,
  onClick,
  points,
  onHandlerClicked,
  onHandlerContextMenu,
  onHandlerHovered,
  onToggleUnitOfMeasure,
  onCreateAnnotation,
  onDeleteActiveMeasurement,
  onChangeMeasurementComponentsToDisplay,
  isClosed = false,
  componentsToDisplay,
  showActionBar = true,
  isLabelVisible,
  toolbarAnchorPoint,
  firstPointIndex,
}: MultiPointRendererProps): JSX.Element | null {
  /** Callback to use when the copy to clipboard action is triggered in the action bar. */
  const onCopyToClipboard = useCallback(
    (contentToCopy: CopyToClipboardParameters) => {
      // Copy the text to the clipboard.
      Analytics.track<CopyMeasurementToClipboardEventProperties>(
        EventType.copyMeasurementToClipboard,
        {
          via: "action bar",
        },
      );
      navigator.clipboard.writeText(
        computeMultiPointMeasurementDescription({
          points,
          isClosed,
          unitOfMeasure,
          componentsToDisplay,
          ...contentToCopy,
        }),
      );
    },
    [isClosed, points, unitOfMeasure, componentsToDisplay],
  );

  const shapeLabelActions = useRef<ShapeLabelActions>(null);

  const [hoveredHandler, setHoveredHandler] = useState(-1);
  const onHandlerEntered = useCallback(
    (index: number) => {
      setHoveredHandler(index);
      onHandlerHovered?.(index);
    },
    [onHandlerHovered],
  );
  const onHandlerLeft = useCallback(() => {
    setHoveredHandler(-1);
    onHandlerHovered?.(-1);
  }, [onHandlerHovered]);

  const handlerTexture = useSvg(LineHandler);

  // The points inside the measurement do know not about any external pose
  // but we need it to correctly compute distances and area
  const groupRef = useRef<Group>(null);
  const [worldMatrix, setWorldMatrix] = useState(new Matrix4());
  useFrame(() => {
    if (!groupRef.current) return;
    if (groupRef.current.matrixWorld.equals(worldMatrix)) return;
    setWorldMatrix(groupRef.current.matrixWorld.clone());
  });

  if (!visible) {
    return null;
  }

  return (
    <group ref={groupRef}>
      <MultiSegmentRenderer
        points={points}
        isClosed={isClosed}
        labelContainer={labelContainer}
        labelsPointerEvents={labelsPointerEvents}
        unitOfMeasure={unitOfMeasure}
        onClick={onClick}
        isActive={isMeasurementActive}
        worldMatrix={worldMatrix}
        depthTest={depthTest}
        isLabelVisible={isLabelVisible}
        componentsToDisplay={componentsToDisplay}
      />
      {!live && (
        <ShapeLabel
          isClosed={isClosed}
          parentRef={labelContainer}
          points={points}
          unitOfMeasure={unitOfMeasure}
          transparent={!isMeasurementActive}
          pointerEvents={labelsPointerEvents}
          actions={shapeLabelActions}
          onClick={(p) => onClick?.(p)}
          worldMatrix={worldMatrix}
        />
      )}
      {points.map((p, index) => (
        <HandlerRenderer
          key={index}
          color={neutral[0]}
          texture={handlerTexture}
          position={p}
          size={BIG_HANDLER_SIZE * (index === hoveredHandler ? 2 : 1)}
          onPointerEnter={live ? () => onHandlerEntered(index) : undefined}
          onPointerLeave={live ? () => onHandlerLeft() : undefined}
          onClick={live ? (ev) => onHandlerClicked?.(ev, index) : undefined}
          onContextMenu={
            live ? (ev) => onHandlerContextMenu?.(ev, index) : undefined
          }
        />
      ))}
      {!live && showActionBar && toolbarAnchorPoint && (
        <MeasureActionBar
          anchorPoint={toolbarAnchorPoint}
          isActive={isMeasurementActive}
          parentRef={labelContainer}
          unitOfMeasure={unitOfMeasure}
          onCreateAnnotation={onCreateAnnotation}
          onDeleteMeasurement={onDeleteActiveMeasurement}
          onCopyToClipboard={onCopyToClipboard}
          onToggleUnitOfMeasure={onToggleUnitOfMeasure}
          onChangeMeasurementComponentsToDisplay={
            onChangeMeasurementComponentsToDisplay
          }
          componentsToDisplay={componentsToDisplay}
          firstPointIndex={firstPointIndex}
        />
      )}
    </group>
  );
}
